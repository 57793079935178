import Button from "@/components/buttons";
import { useZStore } from "@/utils/z-store";
import { FaMailchimp } from "react-icons/fa";
import TextComponent from "../TextComponent";
import cn from "@/utils/cn";

const DashboardWelcome = () => {
  const { actions } = useZStore();

  return (
    <div
      // className="min-h-[331.33px] w-[232px] overflow-hidden rounded-[20px] bg-white md:h-[232.24px]
      //               md:min-h-min md:w-[485px]
      //               lg:h-[300px] lg:w-[800px]
      //               xl:h-[351px] xl:w-[1116px]"
      className={cn(
        "min-h-[331px] md:min-h-min w-[232px] overflow-hidden rounded-[20px] bg-white md:h-[233px] md:w-[485px] xl:h-[351px] xl:w-[1116px]",
      )}
    >
      <div className="flex h-full flex-col sm:flex-col md:flex-row">
        <div className="relative flex grow flex-col items-center justify-center bg-kd-lte-blue  p-5 text-white md:p-11 xl:p-10">
          <div className="max-w-full text-center sm:max-w-[200px] md:max-w-[245px] md:text-start xl:max-w-[581.95px]">
            <TextComponent
              type="h2"
              // className="mb-2 text-center text-[17px] font-semibold
              //              leading-tight md:text-left lg:text-[24px] xl:text-[34px] xl:leading-[40px]"
            >
              Welcome to Email Audit Engine
            </TextComponent>
            <TextComponent
              type="body"
              // className="text-center text-[7px] font-normal
              //             leading-snug md:text-left lg:text-[10px] xl:text-[14px] xl:leading-[25px]"
            >
              Monitor your performance by choosing between the various metric
              displays we offer. Track your most important data and easily
              export them as curated reports to gain the necessary insights into
              your email campaigns. Start by connecting your MailChimp API to
              Email Audit Engine.
            </TextComponent>
            <Button
              variant="secondary"
              className="mx-auto mt-3 h-[23px] bg-white
                         text-[9px] text-[#336DFF] sm:mt-4 md:mx-0 md:mt-5 md:text-[9px] xl:mt-11 xl:h-10 xl:text-base"
              onClick={() =>
                actions.openModal({ modalName: "mailchimp-api-key" })
              }
            >
              <TextComponent className="text-nowrap" type="button">
                Connect MailChimp Integration
              </TextComponent>
            </Button>
          </div>
        </div>
        <div className="my-[30px] bg-white md:my-0 md:w-[162px] xl:w-[372px]">
          <div className="flex h-full flex-col items-center justify-center px-[19px] text-center md:px-[14px] xl:px-[28px] 2xl:px-10">
            <div className="flex size-10 items-center justify-center rounded-full bg-[#FFDD22] xl:size-20">
              <FaMailchimp className="size-full p-1" />
            </div>

            <TextComponent
              type="h3"
              className="mt-2.5 font-semibold text-[#05004E] xl:mt-3 2xl:mt-[9.5px]"
            >
              Connect Mailchimp
            </TextComponent>

            <TextComponent type="body" className="mt-[5px] text-[#96A5B8]">
              Integrate Mailchimp to coordinate cross-channel email campaigns.
            </TextComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWelcome;
