import cn from "@/utils/cn";

interface TextProps {
  type: "title" | "h1" | "h2" | "h3" | "h4" | "body" | "caption" | "button";
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const TextComponent: React.FC<TextProps> = ({
  type,
  children,
  className,
  ...props
}) => {
  const baseStyles = {
    title: "font-semibold",
    h1: "font-semibold",
    h2: "font-semibold",
    h3: "font-semibold",
    h4: "font-medium",
    body: "font-normal",
    caption: "font-normal",
    button: "font-semibold",
  };

  // const textStyles = {
  //   title: `2xl:text-[50px] xl:text-[34px] md:hidden`,
  //   h1: `2xl:text-[40px] xl:text-[27px] md:hidden`,
  //   h2: `2xl:text-[35px] xl:text-[23px] text-[17px]`,
  //   h3: `2xl:text-[18px] xl:text-[12px] text-[10px]`,
  //   h4: `2xl:text-[16px] xl:text-[10.5px] text-[9px]`,
  //   body: `2xl:text-[14px] xl:text-[9.5px] text-[8px]`,
  //   caption: `2xl:text-[12px] xl:text-[8px] text-[6px]`,
  //   button: `2xl:text-[16px] xl:text-[10.5px] text-[9px]`,
  // };

  const textStyles = {
    title: `xl:text-[50px] sm:text-[34px] `,
    h1: `xl:text-[40px] sm:text-[27px]`,
    h2: `xl:text-[35px] sm:text-[23px] text-[17px]`,
    h3: `xl:text-[18px] sm:text-[12px] text-[10px]`,
    h4: `xl:text-[16px] sm:text-[10.5px] text-[9px]`,
    body: `xl:text-[14px] sm:text-[9.5px] text-[8px]`,
    caption: `xl:text-[12px] sm:text-[8px] text-[6px]`,
    button: `xl:text-[16px] sm:text-[10.5px] text-[9px]`,
  };
  return (
    <div
      className={cn(baseStyles[type], textStyles[type], className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default TextComponent;
