"use client";
import Button from "@/components/buttons";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import BackgroundWaitList from "./assets/join-waitlist-modal-bg.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { FormProvider, useForm } from "react-hook-form";
import { EmailFormSchema, emailFormSchema } from "@/utils/schemas/form-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import InputField from "@/components/input-field";

import { useEffect, useState } from "react";
import { handleEmailFormSubmit } from "@/utils/actions/mailchimp-integration";
import toast from "react-hot-toast";
import { BsCheckCircleFill } from "react-icons/bs";
import { useZStore } from "@/utils/z-store";

const JoinWaitlistModal = () => {
  // const pathname = getPathname();
  // const pathname = usePathname();
  const { actions } = useZStore();

  return (
    <div
      className="container relative m-4 mx-auto min-h-[380px] w-[306px] overflow-hidden rounded-[20px] bg-[#FAFBFC]  pb-[28px] pl-[25px] pr-[26px] pt-[35px] shadow-lg md:min-h-[289px] md:w-[438px] md:pb-[28px] md:pl-[41px] md:pr-[46px] md:pt-[35px] xl:min-h-[429px] xl:w-[730px] xl:pb-[47px] xl:pl-[66px] xl:pr-[70px] xl:pt-[59px]
      "
    >
      {/* <Link href={pathname}> */}
      <div className="" onClick={() => actions.closeModal()}>
        <Image
          src={CloseIcon}
          alt="close icon"
          className="absolute right-[23px] top-[20px] z-10 cursor-pointer hover:opacity-60"
        />
      </div>
      {/* </Link> */}

      <Image
        src={BackgroundWaitList}
        alt="close icon"
        className="absolute  bottom-2 right-0 hidden md:-right-10 md:block xl:right-0"
      />
      <div className="relative z-10">
        <h1 className="text-[15px] font-semibold leading-6 text-[#05004E] xl:text-[20px] xl:leading-[32px] ">
          {`Woah, you're early!`}
        </h1>
        <p className="text-left text-[25px] font-semibold leading-[26px] text-[#05004E] xl:text-[34px] xl:leading-[40px]">
          Come back soon!
        </p>
        <div className="mt-[6px]">
          <p className="pr-10 text-[9px] font-medium leading-4 text-kd-gray-text md:text-[10.8px] xl:text-[14px] xl:leading-[25px]">
            Unlock exclusive early access and dominate email campaigns with our
            revolutionary software before anyone else!
          </p>

          <JoinWaitlistForm closeModal={() => actions.closeModal()} />
        </div>
      </div>
    </div>
  );
};

export default JoinWaitlistModal;

const JoinWaitlistForm = ({ closeModal = () => {} }: any) => {
  const methods = useForm<EmailFormSchema>({
    resolver: zodResolver(emailFormSchema),
  });
  const [formProcessing, setFormProcessing] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showSuccessMessage) {
      timeout = setTimeout(() => setShowSuccessMessage(false), 5000);
    }
    return () => clearTimeout(timeout);
  }, [showSuccessMessage]);

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(async (data) => {
            setFormProcessing(true);
            let status;
            try {
              const response = await handleEmailFormSubmit(data); // DEV: We cannot directly pass the function to onSubmit due to some actions-ey stuff. Figure out later.
              status = response.status;
              console.log("response", response);
              // toast.success("Thanks, you're in!", {
              //   duration: 10000,
              //   style: {},
              // });
              setShowSuccessMessage(true);

              methods.reset();
            } catch (error) {
              // toast({ message: error.message, type: "error" });
              // Don't wanna log the error here, since this is a 'use client' file, any errors logged here will be visible in the browser console, even in production. We don't want that. The 'use server' files are already logging these errors, and are presenting them in the EC2 PM2 logs.
              toast.error("Something went wrong");
            } finally {
              setFormProcessing(false);
              // closeModal();
            }
            if (status === "success") {
              methods.reset();
            } else {
              // Here, message necessarily contains an error message.
              // toast({ message: message as string, type: "error" });
            }
          })}
        >
          <div
            // className="mt-4 flex flex-col items-center justify-center space-y-4 md:flex-row md:items-end md:space-x-4 md:space-y-0"

            className="mt-5 xl:mt-[42px]"
          >
            <div className="mb-5 flex flex-col items-center justify-center space-y-2.5 md:flex-row md:space-x-[20px] md:space-y-0 xl:mb-[23px]">
              <InputField
                name="name"
                // className="w-[300px] md:w-[200px] xl:pb-2.5 xl:pt-3"
                // className="h-[30px] w-[300px] rounded-[9.6px] border border-[#EDF2F6] md:w-[290px] lg:h-10 lg:rounded-[12px] xl:w-[466px] xl:pb-2.5 xl:pt-3"
                type="text"
                placeholder="Name"
                className="h-[45px] w-[254px] rounded-[10px] md:w-[172px] xl:h-[60px] xl:w-[287px] xl:rounded-2xl"
                label="First Name"
                labelClassName="text-[11px] leading-[13px] xl:text-base xl:leading-[20px] mb-2.5 whitespace-nowrap"
                errorTextPosition="top"
              />
              <InputField
                name="email"
                type="email"
                // className="h-[30px] w-[312px] rounded-[9.6px] border border-[#EDF2F6] md:w-[290px] lg:h-10 lg:rounded-[12px] xl:w-[466px] xl:pb-2.5 xl:pt-3"
                placeholder="Email"
                className="h-[45px] w-[254px] rounded-[10px] md:w-[172px] xl:h-[60px] xl:w-[287px] xl:rounded-2xl"
                label="Email address"
                labelClassName="text-[11px] leading-[13px] xl:text-base xl:leading-[20px] mb-2.5 whitespace-nowrap"
                errorTextPosition="top"
              />
            </div>
            {showSuccessMessage && <SuccessMessageToast />}

            <div className="flex space-x-5">
              <Button
                type="submit"
                variant="primary"
                className="h-[32px] whitespace-nowrap rounded-[9.6px] border border-[#61A1FF] px-[12.5px] xl:h-10 xl:rounded-[12px] xl:px-[15px]"
                disabled={formProcessing}
              >
                Join the Waitlist
              </Button>
              <p className="w-[45%] text-[7px] leading-[12px] text-[#D8D8D8] xl:text-[10px] xl:leading-[16px]">
                {`By clicking this button, you agree to Email Audit Engine’s Terms
                of Services and Privacy Policy.`}
              </p>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const SuccessMessageToast = () => {
  return (
    <div className=" -bottom-12 flex w-full  justify-center pb-4 pt-0 text-center text-sm font-semibold  xl:text-base">
      <div className=" w-fit rounded-lg  border border-[#EDF2F6] bg-[#F6F6F6] px-2 py-1 text-kd-lte-blue">
        <BsCheckCircleFill className="mr-1 inline text-[16px] text-green-400" />
        {`Thanks, you're in!`}
      </div>
    </div>
  );
};
