"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputField from "@/components/input-field";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import Link from "next/link";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { usePathname } from "next/navigation";
import { useZStore } from "@/utils/z-store";
import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { toast } from "react-hot-toast";

const signInSchema = z.object({
  // usernameOrEmail: z.union([
  //   z.string().email("Please enter a valid email address."),
  //   z.string().min(3, "Username must be at least 3 characters long."),
  // ]),
  email: z.string().email("Please enter a valid email address."),
  password: z
    .string()
    .min(8, "Your password must be at least 8 characters long.")
    .regex(/[A-Z]/, "Your password must contain at least one capital letter.")
    .regex(/[0-9]/, "Your password must contain at least one number."),
});

type SignInFormData = z.infer<typeof signInSchema>;

interface SignInModalProps {
  inviteToken?: string;
  className?: string;
}
const SignInModal: React.FC<SignInModalProps> = ({ inviteToken }) => {
  // const pathname = usePathname();
  const { actions } = useZStore();
  const router = useRouter();
  const methods = useForm<SignInFormData>({
    resolver: zodResolver(signInSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: SignInFormData) => {
    try {
      const response = await signIn("credentials", {
        email: data.email,
        password: data.password,
        redirect: false,
      });

      // console.log({ response });

      if (!response?.error) {
        toast.success("Successfully signed in!", {
          position: "bottom-left",
        });
        router.push("/dashboard/home");
        router.refresh();
        actions.closeModal();
      } else {
        toast.error(response.error, {
          position: "bottom-left",
        });
      }
    } catch (error) {
      // console.error("Sign-in error:", error);
      toast.error("An unexpected error occurred. Please try again.", {
        position: "bottom-left",
      });
    }
  };

  const handleGoogleSignIn = () => {
    if (inviteToken) {
      // Store the inviteToken in localStorage before redirecting to Google
      localStorage.setItem("inviteToken", inviteToken);
      // alert(inviteToken);
    }
    actions.closeModal();
    signIn("google", { callbackUrl: "/dashboard/home", redirect: true });
    // alert(1);
  };

  const handleAppleSignIn = () => {
    signIn("apple", { callbackUrl: "/dashboard/home" });
  };

  const handleForgotPassword = () => {};
  return (
    <div>
      <div
        className={cn(
          "relative min-h-[336px] w-[306px] rounded-[16px] bg-white md:min-h-[317px] md:w-[438px] xl:min-h-[488px] xl:w-[730px] xl:rounded-[20px]",
          "pt-[26px] md:pt-[25px] xl:pt-[63px] pl-[25px] md:pl-[40px] xl:pl-[66px] pr-[26px] md:pr-[42px] xl:pr-[70px] pb-[28px] md:pb-[28px] xl:pb-[55px]",
          "xl:scale-90",
        )}
      >
        <h2 className="mb-[9px] text-[25px] font-semibold leading-[30px]  text-[#05004E] md:mb-0.5 md:text-[25px] md:leading-[37.5px] xl:text-[34px] xl:leading-[40px]">
          Welcome Back
        </h2>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 xl:mt-6">
              <InputField
                id="sign-in-email"
                name="email"
                type="text"
                placeholder="hello@emailauditengine.com "
                className="mb-[5px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal xl:mb-[27px] xl:h-[60px] xl:rounded-2xl"
                label="Username or Email"
                labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                errorTextPosition="top"
              />
              <div className="">
                <InputField
                  name="password"
                  label="Password"
                  type="password"
                  className=" flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal  xl:h-[60px] xl:rounded-2xl"
                  placeholder="***"
                  labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                  isRequired={true}
                  errorTextPosition="top"
                />
                <p
                  // onClick={() => actions.openModal({ modalName: "sign-up" })}
                  onClick={handleForgotPassword}
                  className="mt-2 cursor-pointer text-right text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] xl:mt-[9px] xl:text-[14px] xl:leading-[20px]"
                >
                  {`Forgot password?`}
                </p>
              </div>
            </div>

            <div className="mt-2 flex items-center justify-center space-x-[10px] md:space-x-[15px] xl:mt-0 xl:space-x-[30px]">
              <Button
                type="submit"
                variant="primary"
                className="h-[32px] w-[119.6px] whitespace-nowrap rounded-lg px-4 text-[12.8px] font-semibold leading-5 xl:h-10 xl:w-[150px] xl:text-base xl:leading-[24px]"
              >
                Continue
              </Button>
              {/* <p className="w-[263px] text-[7px] leading-3 text-[#D8D8D8] xl:text-[10px] xl:leading-4">
                {`By clicking this button, you agree to Email Audit Engine's Terms of
          Services and Privacy Policy.`}
              </p> */}
            </div>
          </form>
        </FormProvider>

        <div className="my-5 flex items-center justify-center space-x-5 text-[#E1E1E1] xl:my-[22px]">
          <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
          <p>or</p>
          <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
        </div>

        <div className="mt-4 flex flex-col space-y-[16px]">
          {/* <Button
            onClick={handleAppleSignIn}
            className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text xl:h-[60px] xl:rounded-[16px]"
          >
            <AiFillApple className="size-5" />
            <span>Sign in with Apple</span>
          </Button> */}

          <Button
            onClick={handleGoogleSignIn}
            className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text xl:h-[60px] xl:rounded-[16px]"
          >
            <FcGoogle className="size-5" />
            <span>Sign in with Google</span>
          </Button>
        </div>

        <p className="mt-[22px] text-center text-[9px] font-medium leading-[16px] text-[#D8D8D8] md:text-[10.8px] md:leading-[12px] xl:mt-[28px] xl:text-[14px] xl:leading-[20px]">
          Don’t have an account?
          <span
            onClick={() => actions.openModal({ modalName: "sign-up" })}
            className="cursor-pointer text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] lg:text-[14px] lg:leading-[20px]"
          >
            {` Sign up `}
          </span>
        </p>

        <div className="" onClick={() => actions.closeModal()}>
          <Image
            src={CloseIcon}
            alt="close icon"
            className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
          />
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
