"use client";
import { useEffect, Suspense } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { useZStore } from "@/utils/z-store";
import cn from "@/utils/cn";
import {
  CreateAccountFormModal,
  DashboardIntro,
  DashboardWelcomeModal,
  JoinWaitlistModal,
  MailchimpAPISettingsModal,
  SignInModal,
  ReportBuilderModal,
} from "./modals";

interface ModalProps {
  className?: string;
  inviteToken?: string;
}

const modalComponents: any = {
  "start-free-trial": JoinWaitlistModal,
  "sign-up": CreateAccountFormModal,
  "sign-in": SignInModal,
  "dashboard-welcome": DashboardWelcomeModal,
  "mailchimp-api-key": MailchimpAPISettingsModal,
  "report-builder": ReportBuilderModal,
  "dashboard-intro": DashboardIntro,
};

const ModalContent: React.FC<ModalProps> = ({ className }) => {
  const { data, actions } = useZStore();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const isHomePage = pathname === "/";
  const isDashboardPageModal = ![
    "start-free-trial",
    "sign-up",
    "sign-in",
  ].includes(data.modal.modalName || "");

  const modalName = searchParams.get("modal");
  const inviteToken = searchParams.get("inviteToken");

  useEffect(() => {
    if (modalName) {
      actions.openModal({ modalName });
    }
  }, [searchParams, actions]);

  if (!data.modal.isOpen) return null;

  const ModalComponent = data.modal.modalName
    ? modalComponents[data.modal.modalName || ""]
    : null;

  return (
    <dialog
      className={cn(
        "fixed left-0 top-0 z-40 flex h-full w-full items-center justify-center overflow-auto bg-black/[40%]",
        className,
        {
          hidden: isHomePage && isDashboardPageModal,
        },
      )}
    >
      {data.modal.content && data.modal.modalName == null && (
        <div className="">{data.modal.content}</div>
      )}
      {ModalComponent && (!isHomePage || !isDashboardPageModal) && (
        <ModalComponent inviteToken={inviteToken} />
      )}
    </dialog>
  );
};

const Modal: React.FC<ModalProps> = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ModalContent {...props} />
    </Suspense>
  );
};

export default Modal;
