import axios from "axios";

export const fetchMailchimpProfileByEmail = async (email: string) => {
  try {
    const { data } = await axios.post("/api/mailchimp-profile", {
      userEmail: email,
    });
    return data;
  } catch (error) {
    console.error("Error fetching Mailchimp profile:", error);
    return null;
  }
};

export const postProcessedData = async (
  userEmail: string,
  keyId: string,
  processedData: Record<string, any>,
) => {
  try {
    const { data } = await axios.post("/api/mailchimp-profile/processed-data", {
      userEmail,
      keyId,
      processedData,
    });
    return data;
  } catch (error) {
    console.error("Error posting processedData:", error);
    return null;
  }
};

export const postMailchimpData = async (
  userEmail: string,
  newMailchimpData: Record<string, any>,
) => {
  try {
    const { data } = await axios.post("/api/mailchimp-profile/mailchimp-data", {
      userEmail,
      newMailchimpData,
    });
    return data;
  } catch (error) {
    console.error("Error posting mailchimpData:", error);
    return null;
  }
};

// Add or update single mailchimpData entry
export const putMailchimpData = async (
  userEmail: string,
  newMailchimpData: Record<string, any>,
) => {
  try {
    const { data } = await axios.put("/api/mailchimp-profile/mailchimp-data", {
      userEmail,
      newMailchimpData,
    });
    return data;
  } catch (error) {
    console.error("Error updating mailchimpData:", error);
    return null;
  }
};

// Function to update selected Mailchimp API key
export const updateSelectedMailchimpKey = async (
  userEmail: string,
  selectedKeyId: string,
) => {
  try {
    const { data } = await axios.post(
      "/api/mailchimp-profile/update-selected-key",
      {
        userEmail,
        selectedKeyId,
      },
    );
    return data;
  } catch (error) {
    console.error("Error updating selected Mailchimp key:", error);
    return null;
  }
};

// Function to get all Mailchimp data for a user
export const getAllMailchimpData = async (userEmail: string) => {
  try {
    const { data } = await axios.get(
      `/api/mailchimp-profile/mailchimp-data?userEmail=${encodeURIComponent(userEmail)}`,
    );
    return data;
  } catch (error) {
    console.error("Error fetching Mailchimp data:", error);
    return null;
  }
};

// Function to get specific Mailchimp data by keyId
export const getSpecificMailchimpData = async (
  userEmail: string,
  keyId: string,
) => {
  try {
    const { data } = await axios.get(
      `/api/mailchimp-profile/mailchimp-data?userEmail=${encodeURIComponent(userEmail)}&keyId=${encodeURIComponent(keyId)}`,
    );
    return data;
  } catch (error) {
    console.error("Error fetching specific Mailchimp data:", error);
    return null;
  }
};

export const fetchMailchimpAccountDetails = async (
  apiKey: string,
): Promise<any> => {
  const [token, server] = apiKey.split("-");
  try {
    const response = await axios.get(
      `/api/mailchimp?apiKey=${encodeURIComponent(token)}&server=${encodeURIComponent(server)}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Mailchimp account details:", error);
    return null; // Or throw the error, depending on how you want to handle it
  }
};
