import { ReportBuilderModalImage } from "@/assets/svg-icons/svg-icons";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";
import { FaPlay } from "react-icons/fa6";
import TextComponent from "../TextComponent";

const ReportBuilderModal = () => {
  const { actions } = useZStore();
  // new modal
  return (
    <div
      className={cn(
        "flex overflow-hidden shadow-md rounded-[20px] flex-col md:flex-row",
        "xl:w-[1116px] xl:h-[352px]", //xl & 2xl
        "md:w-[585px] md:h-[233px]",
        "w-[233px] h-[568px]",
      )}
    >
      <div className="size-full md:w-1/2">
        {/* Placeholder for video */}
        <div className="relative size-full ">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="z-10 flex size-[49px] cursor-pointer  items-center justify-center rounded-full bg-black/10 pl-1 xl:size-[79px]">
              <TextComponent type="h2">
                <FaPlay className="text-white" />
              </TextComponent>
            </div>
          </div>
          {/* Blurred Overlay (optional) */}
          <div className="absolute inset-0 bg-gray-800 opacity-25"></div>
        </div>
      </div>

      <div
        className={cn(
          "flex w-full flex-col justify-between bg-white",
          "xl:pt-[72px] xl:pb-[48px] xl:px-[107px]",
          "px-[48px] py-10",
        )}
      >
        <div className="">
          <TextComponent type="h2" className="mb-[6px] text-kd-lte-blue">
            Customize the Report
          </TextComponent>
          <TextComponent type="body" className="text-kd-gray-text">
            Compile your organized dashboards into clear, detailed, curated
            reports and export them as a PDF to clients and users. Create your
            report by selecting the available dashboards from the side panel and
            compile them as pages into a report.
          </TextComponent>
        </div>

        <Button
          variant="primary"
          className="mt-4 w-fit md:mt-0"
          onClick={() => {
            actions.closeModal();
            actions.setSideBarVisible(false);
          }}
        >
          <TextComponent type="h4">Continue</TextComponent>
        </Button>
      </div>
    </div>
  );

  // old modal
  return (
    <div
      className="h-fit w-full max-w-[232px] overflow-hidden rounded-[20px]
                    bg-white shadow-lg md:h-[232.24px] md:max-w-[485px]
                    lg:h-[300px] lg:max-w-[800px] xl:h-[351px] xl:max-w-[1116px]"
    >
      <div className="flex h-full flex-col md:flex-row">
        {/* Left side */}
        <div className="relative flex grow flex-col items-center justify-center bg-[#336DFF] p-4 text-white sm:p-3 md:p-4 lg:p-5 xl:p-6">
          <div className="max-w-full sm:max-w-[200px] md:max-w-[300px] lg:max-w-[400px] xl:max-w-[581.95px]">
            <h1 className="mb-2 text-center text-[17px] font-semibold leading-tight md:text-left lg:text-[24px] xl:text-[34px] xl:leading-[40px]">
              Welcome to your Report Builder
            </h1>
            <p className="mb-3 mt-[7px] text-center text-[10px] font-semibold md:text-left lg:text-[14px] xl:text-[20px]">
              Share your custom report and showcase your data.
            </p>
            <p className="text-center text-[7px] font-normal leading-snug md:text-left lg:text-[10px] xl:text-[14px] xl:leading-[25px]">
              Build your report by selecting your dashboard templates you want
              to combine into a PDF document to share and highlight key metrics
              to peers and clients.
            </p>
            <Button
              onClick={() => {
                actions.closeModal();
                actions.setSideBarVisible(false);
              }}
              className="mx-auto mt-3 h-[23px] rounded-md bg-white px-4 text-[9px] font-semibold text-[#336DFF] sm:mt-4 md:mx-0 md:mt-5 lg:mt-6 xl:mt-11 xl:h-10 xl:text-base"
            >
              Continue to Builder
            </Button>
          </div>
        </div>

        {/* Right side */}
        <div className="flex w-full scale-90 items-center justify-center bg-white p-3 sm:w-full sm:p-3 md:w-[160px] md:p-3 lg:w-[260px] lg:p-4 xl:w-[372px] xl:p-6">
          <ReportBuilderModalImage />
        </div>
      </div>
    </div>
  );
};

export default ReportBuilderModal;
