"use client";
import Button from "@/components/buttons";
import SideImage from "./assets/modal-side-image.svg";
import CloseIcon from "./assets/close-icon.svg";
import Image from "next/image";
import InputField from "@/components/input-field";
import { FormProvider, useForm } from "react-hook-form";
import {
  CreateAccountFormSchema,
  createAccountFormSchema,
} from "@/utils/schemas/create-account-form-schema";
import { zodResolver } from "@hookform/resolvers/zod";

import { useZStore } from "@/utils/z-store";
import cn from "@/utils/cn";
import axios from "axios";
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { useState } from "react";
import { signIn } from "next-auth/react";

interface CreateAccountFormModalProps {
  inviteToken?: string;
  className?: string;
}

const CreateAccountFormModal: React.FC<CreateAccountFormModalProps> = ({
  inviteToken,
  className,
}) => {
  // const pathname = usePathname();
  const { actions } = useZStore();
  const [loading, setLoading] = useState(false);
  const methods = useForm<CreateAccountFormSchema>({
    resolver: zodResolver(createAccountFormSchema),
  });
  // const [, setFormProcessing] = useState(false);
  const isPasswordInvalid =
    methods.formState.errors.password?.message ||
    methods.formState.errors.confirmPassword?.message;

  const acceptInvitation = async (email: string, token: string) => {
    try {
      const response = await axios.post("/api/invitations/accept", {
        token,
        email,
      });

      if (response.status === 200) {
        toast.success("Invitation accepted successfully.", {
          position: "bottom-left",
        });
      } else {
        toast.error(response.data.message || "Failed to accept invitation.", {
          position: "bottom-left",
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage =
          error.response?.data?.message || "Failed to accept invitation.";
        toast.error(errorMessage, {
          position: "bottom-left",
        });
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          position: "bottom-left",
        });
      }
    }
  };

  const registerUser = async (formData: any) => {
    setLoading(true);
    if (formData.password !== formData.confirmPassword) {
      // console.error("Passwords do not match");
      return;
    }

    try {
      // console.log(formData);
      const response = await axios.post("/api/auth/register", formData);
      // console.log(response.data);
      toast.success("Successfully signed up!", {
        position: "bottom-left",
      });
      if (response.status === 200) {
        // actions.openModal({ modalName: "sign-in" });
        if (inviteToken) {
          await acceptInvitation(formData.email, inviteToken);
        }
        await signIn("credentials", {
          email: formData.email,
          password: formData.password,
          callbackUrl: "/dashboard/home",
          redirect: true,
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle axios-specific errors
        const errorMessage =
          error.response?.data?.message || "Failed to sign up.";
        toast.error(errorMessage, {
          position: "bottom-left",
        });
      } else {
        // Handle other errors
        // console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred. Please try again.", {
          position: "bottom-left",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const handleGoogleSignIn = () => {
    if (inviteToken) {
      // Store the inviteToken in localStorage before redirecting to Google
      localStorage.setItem("inviteToken", inviteToken);
    }
    signIn("google", {
      callbackUrl: "/dashboard/home",
    });
  };

  return (
    <FormProvider {...methods}>
      <div
        className={cn(
          "relative flex w-[306px] items-center justify-center overflow-hidden rounded-[20px]  bg-white md:h-[620px] md:w-[657px] xl:h-[800px] xl:w-[1095px]",
          {
            "min-h-[550px] xl:min-h-[796px]": isPasswordInvalid,
          },
          "scale-95 xl:h-[796px] xl:scale-80 ",
        )}
      >
        <div
          className={cn(
            "h-full w-full bg-white pb-[28px] pl-[25px] pr-[26px] pt-[29px] leading-5 text-[#444A6D] md:w-[65%] md:pl-[40px] md:pr-[42px] md:pt-[25px] xl:pb-[56px] xl:pl-[66px] xl:pr-[70px] xl:pt-[40px]",
            {
              "h-fit": isPasswordInvalid,
            },
          )}
        >
          <form onSubmit={methods.handleSubmit(registerUser)}>
            <h2 className="mb-[9px] text-[25px] font-semibold leading-[30px]  text-[#05004E] md:mb-0.5 md:text-[25px] md:leading-[37.5px] xl:text-[34px] xl:leading-[40px]">
              Create A Free Account
            </h2>
            <p className="mb-5 text-[9px] font-medium leading-[16px] md:text-[10.8px]  md:leading-[12px] xl:mb-[41px] xl:text-[14px] xl:leading-[20px]">
              Start a free trial or
              <span
                onClick={() => actions.openModal({ modalName: "sign-in" })}
                className="cursor-pointer text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] xl:text-[14px] xl:leading-[20px]"
              >
                {` sign in `}
              </span>
              if you already have an account
            </p>

            <InputField
              name="email"
              label="Email address"
              className="mb-[11px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal xl:mb-[27px] xl:h-[60px] xl:rounded-2xl "
              placeholder="hello@emailauditengine.com"
              labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
              isRequired={true}
              errorTextPosition="right"
            />
            <InputField
              name="name"
              label="Full name"
              className="mb-[11px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal xl:mb-[27px] xl:h-[60px] xl:rounded-2xl"
              placeholder="John Doe"
              labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
              isRequired={true}
              errorTextPosition="right"
            />

            <div className="flex flex-col md:flex-row md:space-x-5">
              <InputField
                name="password"
                label="New password"
                type="password"
                className="mb-[11px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal xl:mb-[27px] xl:h-[60px] xl:rounded-2xl"
                placeholder=""
                labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                isRequired={true}
                errorTextPosition="right"
                showErrorInTooltip={true}
              />

              <InputField
                name="confirmPassword"
                label="Confirm new password"
                type="password"
                className="mb-[11px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal xl:mb-[27px] xl:h-[60px] xl:rounded-2xl"
                placeholder=""
                labelClassName="text-[11px] font-medium leading-[13px] xl:text-base xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                isRequired={true}
                errorTextPosition="right"
                showErrorInTooltip={true}
              />
            </div>

            <div className="flex items-center justify-center space-x-[10px] md:space-x-[15px] xl:space-x-[30px]">
              <Button
                type="submit"
                variant="primary"
                className={cn(
                  "h-[32px] w-[119.6px] whitespace-nowrap rounded-lg px-4 text-[12.8px] font-semibold leading-5 xl:h-10 xl:w-[150px] xl:text-base xl:leading-[24px]",
                )}
                disabled={loading}
              >
                Start Free Trial
              </Button>
              {/* <p className="w-[263px] text-[7px] leading-3 text-[#D8D8D8] xl:text-[10px] xl:leading-4">
                {`By clicking this button, you agree to Email Audit Engine’s Terms
                of Services and Privacy Policy.`}
              </p> */}
            </div>

            <div className="my-5 flex items-center justify-center space-x-5 text-[#E1E1E1] xl:my-[18px]">
              <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
              <p>or</p>
              <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
            </div>
          </form>
          {/* signup with google and apple */}
          <div className="mt-4 flex flex-col space-y-[16px]">
            {/* <Button
              onClick={() => {
                alert(1);
              }}
              className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text xl:h-[60px] xl:rounded-[16px]"
            >
              <AiFillApple className="size-5" />
              <span>Sign up with Apple</span>
            </Button> */}

            <Button
              onClick={handleGoogleSignIn}
              className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text xl:h-[60px] xl:rounded-[16px]"
            >
              <FcGoogle className="size-5" />
              <span>Sign up with Google</span>
            </Button>
          </div>
          <p className="mt-[22px] text-center text-[9px] font-medium leading-[16px] text-[#D8D8D8] md:text-[10.8px] md:leading-[12px] xl:mt-[20px] xl:text-[14px] xl:leading-[20px]">
            Do you have an account?
            <span
              onClick={() => actions.openModal({ modalName: "sign-in" })}
              className="cursor-pointer text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] xl:text-[14px] xl:leading-[20px]"
            >
              {` Sign in `}
            </span>
          </p>
        </div>

        {/* side image */}
        <div className="relative hidden h-full w-[35%] overflow-hidden bg-kd-lte-blue text-white md:block">
          <div className="absolute -right-48 -top-32 -z-0 size-[300px] rotate-90 rounded-full bg-gradient-to-tl  from-white/40 via-white/10 to-white/0 xl:-right-28 xl:-top-28"></div>

          <div className="absolute -bottom-52 -left-44 -z-0 size-[300px] rounded-full bg-gradient-to-bl from-white/40 via-transparent to-white/0 xl:-bottom-28 xl:-left-44"></div>
          <div className=" flex h-full flex-col justify-evenly md:px-6 xl:px-12">
            <Image
              src={SideImage}
              alt="laptop image"
              // className="absolute top-20 z-10 md:-right-3 md:scale-90 xl:right-0 xl:scale-100"
              className="md:scale-90 xl:scale-100"
            />
            <div
              // className="absolute bottom-[47px] text-xs md:px-6 xl:px-12"
              className="text-xs "
            >
              <h3 className="mb-[5px] text-left text-xs font-medium leading-5 xl:text-xl xl:leading-[32px]">
                Unlock exclusive features
              </h3>

              <div className="space-y-0.5 text-left font-medium ">
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  <span className="font-bold md:font-normal xl:font-normal">
                    White-label
                  </span>{" "}
                  your reports
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  Allow for{" "}
                  <span className="font-bold md:font-normal xl:font-normal">
                    collaborative strategizing
                  </span>
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  Have{" "}
                  <span className="font-bold md:font-normal xl:font-normal">
                    AI assist
                  </span>{" "}
                  you, saving you time
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  Create a{" "}
                  <span className="font-bold md:font-normal xl:font-normal">
                    lead magnet
                  </span>{" "}
                  for your website
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  <span className="font-bold md:font-normal xl:font-normal">
                    Get suggestions
                  </span>{" "}
                  on how to get better ROI
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  Compare metrics with your{" "}
                  <span className="font-bold md:font-normal xl:font-normal">
                    {`industry's average`}
                  </span>
                </p>
                <p className="text-xs font-semibold md:text-[7px] md:leading-[15px] xl:text-[12px] xl:leading-[25px]">
                  Edit, download & send your{" "}
                  <span className="font-bold md:font-normal xl:font-normal">
                    audits reports
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* close icon */}
        <div className="" onClick={() => actions.closeModal()}>
          <Image
            src={CloseIcon}
            alt="close icon"
            className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
          />
        </div>
      </div>
    </FormProvider>
  );
};

export default CreateAccountFormModal;
