import { z } from "zod";

const isFullName = (val: string) => {
  const parts = val.trim().split(/\s+/);
  return parts.length >= 2;
};

export const emailFormSchema = z.object({
  // name: z.string().refine(isFullName, "Please enter your full name"),
  name: z.string().min(2, "Please enter your first name."),
  email: z.string().email("Please enter a valid email address."),
});

export type EmailFormSchema = z.infer<typeof emailFormSchema>;
