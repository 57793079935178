import React, { useState } from "react";
import cn from "@/utils/cn";
import Image from "next/image";
import { useFormContext } from "react-hook-form";
import InputCrossIcon from "@/assets/icons/input-cross-icon.svg";
import { InputErrorIcon } from "@/assets/svg-icons/svg-icons";

type InputFieldProps = {
  name: string;
  label?: string;
  labelClassName?: string;
  isRequired?: boolean;
  subLabel?: string;
  errorTextPosition?: "right" | "top";
  showErrorInTooltip?: boolean;
  labelStyle?: React.CSSProperties;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function InputField({
  name,
  label,
  className,
  labelClassName = "",
  isRequired = false,
  subLabel = "",
  errorTextPosition = "right",
  showErrorInTooltip = false,
  labelStyle = {},
  ...props
}: InputFieldProps) {
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [showTooltip, setShowTooltip] = useState(false);

  const error = errors?.[name]?.message;

  return (
    <div className={cn("w-full")}>
      <div
        className={cn("flex items-center ", {
          "flex-col-reverse items-start": errorTextPosition === "top",
        })}
      >
        {label && (
          <div className="flex items-center">
            <label
              className={cn(
                "text-kd-gray-text font-medium leading-5 xl:text-base text-[10.5px]",
                labelClassName,
              )}
              style={labelStyle}
            >
              {label}
              {!error && (
                <span className="ml-1 text-[9.24px] font-[400] leading-[20px] text-[#96A5B8] xl:text-[14px]">
                  {subLabel}
                </span>
              )}
            </label>
          </div>
        )}

        {error && (
          <div className="ml-2 flex items-start">
            <div
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className="relative -mt-2 flex cursor-pointer items-center"
            >
              <InputErrorIcon />
              {showErrorInTooltip && showTooltip && (
                <div className="absolute left-1/2 top-full z-10 mt-3 -translate-x-1/2 whitespace-nowrap rounded-lg border border-[#FA5A7D] bg-white p-2 text-xs text-[#FA5A7D] shadow-lg">
                  {String(error)}
                  <div className="absolute -top-2 left-1/2 size-3 -translate-x-1/2 rotate-45 border-l border-t border-[#FA5A7D] bg-white"></div>
                </div>
              )}
              {!showErrorInTooltip && (
                <p className="ml-1 text-[9px] text-[#FA5A7D] xl:text-xs">
                  {String(error)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="relative mt-1">
        <input
          id={name}
          className={cn(
            "w-full bg-[#F6F6F6] rounded-[100px] pl-3 pr-9 md:pb-1.5 md:pt-1.5 py-1 placeholder:text-[#979797] xl:placeholder:text-sm placeholder:text-[11px] placeholder:font-medium font-bold relative text-sm focus:outline-none focus:ring-1 focus:ring-kd-lte-blue",
            error && "focus:ring-[#FA5A7D]",
            "h-10 w-full rounded-[10.56px] bg-[#F9FAFB] px-[24px] text-[11px] font-normal xl:h-[60px] xl:rounded-2xl xl:text-sm",
            className,
          )}
          {...props}
          {...register(name)}
        />

        {watch(name) && (
          <div
            onClick={() => {
              setValue(name, "");
            }}
            className="absolute inset-y-0 right-[16px] flex h-full cursor-pointer items-center justify-center"
          >
            <Image src={InputCrossIcon} alt="Clear input field" />
          </div>
        )}
      </div>
    </div>
  );
}
