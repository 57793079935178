"use client";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import { useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useRouter } from "next/navigation";
import { useZStore } from "@/utils/z-store";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const DashboardIntro = () => {
  const { actions } = useZStore();
  const [currentStep, setCurrentStep] = useState(0);
  const router = useRouter();
  const steps = [
    {
      title: "Add Cards",
      heading: "Customize the Dashboard",
      description:
        "Locate relevant cards by scrolling through the side panel or typing keywords into the search bar. Choose between different display variations to represent you data on each card. Add cards by click and dragging them into the available blocks. ",
      videoSrc: "path/to/video1.mp4", // Replace with actual video source
    },
    {
      title: "Save Dashboard",
      heading: "Save Your Custom Dashboard Layout",
      description:
        "Group key cards together and save the current layout as a template for export. You can have multiple custom layouts for different scenarios. Remove, scale or change the layout of your cards before saving it to the dashboard list. ",
      videoSrc: "path/to/video2.mp4", // Replace with actual video source
    },
    {
      title: "Export Report",
      heading: "Export Your Saved Dashboards as a Report",
      description:
        'Once your custom dashboard is saved, you can export its layout as a PDF by selecting the "Export Report" button. Organize your cards with numerous filtering options before compiling and exporting all your saved dashboards as a report.',
      videoSrc: "path/to/video3.mp4", // Replace with actual video source
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Redirect to /dashboard/edit
      router.push("/dashboard/edit");
      actions.closeModal();
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div
      className={cn(
        "flex overflow-hidden rounded-[20px] shadow-md w-[1116px] h-[352px]",
      )}
    >
      <div className="w-1/2 bg-gray-100">
        {/* Placeholder for video */}
        <div className="relative size-full">
          <div className="absolute inset-0 flex items-center justify-center">
            {/* Replace with actual video player */}
            <div className="flex size-24 items-center justify-center rounded-full bg-gray-400 text-4xl text-white">
              {/* <video src={steps[currentStep].videoSrc} controls /> */}▶
            </div>
          </div>

          {/* Blurred Overlay (optional) */}
          <div className="absolute inset-0 bg-gray-800 opacity-25"></div>
        </div>
      </div>

      <div className="flex w-full flex-col justify-between bg-white p-8">
        <div className="mb-4 flex justify-between space-x-4 border-b">
          {steps.map((step, index) => (
            <div
              className={cn(
                "flex items-center space-x-[10px] px-4 pb-1.5",
                index === currentStep ? "border-b-2 border-kd-lte-blue" : "",
              )}
              key={index}
            >
              <div
                className={`flex size-[18px] items-center justify-center p-1 text-xs font-semibold ${
                  index === currentStep ? "text-kd-lte-blue " : "text-[#D9D9D9]"
                }`}
              >
                <span>{index + 1}</span>
              </div>
              <span
                className={cn(
                  "text-xs",
                  index === currentStep
                    ? "text-kd-lte-blue font-semibold"
                    : "text-[#D9D9D9]",
                )}
              >
                {step.title}
              </span>
            </div>
          ))}
        </div>

        <div className="">
          <h2 className="mb-4 text-xl font-semibold text-kd-lte-blue">
            {steps[currentStep].heading}
          </h2>
          <p className="mb-8 text-sm font-medium text-kd-gray-text">
            {steps[currentStep].description}
          </p>
        </div>
        <div className="flex w-full justify-between">
          <button
            onClick={prevStep}
            disabled={currentStep === 0}
            className={cn(
              `flex items-center rounded-lg px-4 py-2 text-[16px] font-semibold text-kd-gray-text hover:bg-kd-gray-text/10 transition-colors`,
              currentStep === 0 ? "opacity-0" : "opacity-100",
            )}
          >
            <FaAngleLeft className="mr-2 size-4" />
            Back
          </button>
          <button
            onClick={nextStep}
            className={cn(
              `flex items-center rounded-lg px-4 py-2 text-[16px] font-semibold transition-colors text-kd-lte-blue hover:bg-kd-lte-blue/10`,
            )}
          >
            Next
            <FaAngleRight className="ml-2 size-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardIntro;
